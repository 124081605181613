import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetApprovalRecordParameters, GetApprovalRecordRes, GetCampaignParameters, GetCampaignRes, PutApprovalRecordParameters } from '../../api';
import { Button, Input, Modal, Select, Space, Switch, Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import JJ_FromAssets from '../../components/JJ_FromAssets';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import JJ_FromI18nTextAreaByDefault from '../../components/JJ_FromI18nTextAreaByDefault';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';


const campaginFormData: { [key: string]: formListDataItem } = {
  quantity: {
    id: 'quantity',
    label: '數量',
    type: 'input',
    disable: true,
    i18n: false,
  },
  description: {
    id: 'description',
    label: '描述',
    type: 'text',
    disable: true,
    i18n: true,
  },
  video_url: {
    id: 'video_url',
    label: '視頻鏈接',
    type: 'input',
    disable: true,
    i18n: false,
  },
  name: {
    id: 'name',
    label: '名稱',
    type: 'input',
    disable: true,
    i18n: true,
  },

  avatar_asset_id: {
    id: 'avatar_asset_id',
    label: '頭像圖片',
    type: 'assets',
    disable: true,
    i18n: false,
  },

  asset_ids: {
    id: 'asset_ids',
    label: '活動圖片',
    type: 'assets',
    disable: true,
    i18n: false,
  },
  with_canstruction: {
    id: 'with_canstruction',
    label: '是否有作品',
    type: 'switch',
    disable: true,
    i18n: false,
  },
}


type formListDataItem = {
  id: string
  label: string
  value?: any
  type: 'input' | 'text' | 'assets' | 'switch'
  disable?: boolean
  i18n?: boolean
  required?: boolean
}

interface PageState {
  approvalRecord?: GetApprovalRecordRes
  campaign?: GetCampaignRes
  formListData: formListDataItem[]
}


interface Props {
  onFinish?(props: PageProps, id: string): void
  id: string
  visible?: boolean
  onCancel?(): void
}


type PageProps = Props




export default class ApprovalRecordDetail extends React.Component<PageProps, PageState>  {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
      formListData: []
    }

  }
  componentDidMount() {
  }

  _putApprovalRecord = async (params: PutApprovalRecordParameters) => {
    const res = await api.PutApprovalRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getApprovalRecord = async (params: GetApprovalRecordParameters) => {
    const res = await api.GetApprovalRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getCampaign = async (params: GetCampaignParameters) => {
    const res = await api.GetCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onFinish = async (): Promise<void> => {

    const res = await api.PutApprovalRecord({
      id: this.props.id,
      isApproved: true,
    })

    this.props.onFinish && this.props.onFinish(this.props, this.props.id)

  }



  _onLoadData = async (): Promise<any> => {
    const approvalRecordRes = await this._getApprovalRecord({ id: this.props.id })
    const campaignRes = await this._getCampaign({ id: approvalRecordRes.campaignId })

    const formListData: formListDataItem[] = []
    const approvalRecordValue = approvalRecordRes.value

    if (approvalRecordValue) {
      for (const key in approvalRecordValue) {
        const campaginFormDataValue = campaginFormData[key]
        if (campaginFormDataValue) {
          let value = approvalRecordValue[key]
          if (key === 'avatarAssetId' && value) {
            value = [value]
          }
          formListData.push({
            ...campaginFormDataValue,
            value,
          })
        }
      }

      this.setState({
        campaign: campaignRes,
        approvalRecord: approvalRecordRes,
        formListData,
      })
      return approvalRecordValue
    }


  };

  _formListData = (): FormItemData[] => {
    return this.state.formListData.map(item => {

      switch (item.type) {
        case 'assets': {
          return {
            id: item.id,
            label: item.label,
            rules: [
              {
                required: item.required,
              }
            ],
            componet: (<JJ_FromAssets />)
          }

        }

        case 'input': {
          return {
            id: item.id,
            label: item.label,
            rules: [
              {
                required: item.required,
              }
            ],
            componet: item.i18n
              ? <JJ_FromI18nInputText disabled={item.disable} />
              : <Input disabled={item.disable} />
          }

        }

        case 'text': {
          return {
            id: item.id,
            label: item.label,
            rules: [
              {
                required: item.required,
              }
            ],
            componet: item.i18n
              ? <JJ_FromI18nTextAreaByDefault disabled={item.disable} />
              : <Input.TextArea
                disabled={item.disable}
                rows={4}
              />
          }

        }

        case 'switch': {
          return {
            id: item.id,
            label: item.label,
            rules: [
              {
                required: item.required,
              }
            ],
            componet: <JJ_FromSwitch switchProps={{ disabled: item.disable }} />
          }

        }

      }
    })

  }

  _footerView = () => {
    return (
      <Space>
        {!this.state.approvalRecord?.approvedAt && (
          <Button
            type="primary"
            size="large"
            onClick={() => this._onFinish()}
            icon={<CheckOutlined />}
          >
            通過審核
          </Button>
        )}
      </Space>
    )
  }

  render() {
    return (
      <Modal
        maskClosable={false}
        title={'審批記錄詳情'}
        visible={this.props.visible}

        footer={this._footerView()}
        onCancel={this.props.onCancel}
        width={'85%'}
      >
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </Modal>
    )
  }

}

