import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetDonationRecordRes, GetDonationRecordListParameters, GetPaymentRes, GetPaymentListParameters, PutApprovalRecordParameters, PostSendEmailReceiptParameters, GetDonationRecordParameters, PutDonationRecordParameters, GetUserParameters, GetCampaignParameters, GetUserRes, GetCampaignRes, GetCampaignListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import DonationRecordEditAndAdd, { Type } from './DonationRecordAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getDonationRecordColumns, DonationRecordColumnData, getDonationRecordColumnStatusTag, getDonationColumnTypeInfo } from '../../table-columns/DonationRecord';
import { ActionUserRes } from '../../actions/ActionUser';
import { ApiConfig } from '../../api/api-config';
import tool from '../../tool';
import config from '../../config';
import { filterParams } from '../../tool/route';

const { confirm } = Modal;

type EditAndAddModalType = Type

interface PageState {
  isSpinLoading: boolean
  initLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
  payments: GetPaymentRes[]
  campaigns: GetCampaignRes[]

}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetDonationRecordRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DonationRecordList extends React.Component<PageProps, PageState> implements Page {


  params: {
    order?: GetDonationRecordListParameters['order']
    paymentId?: GetDonationRecordListParameters['paymentId']
    type?: GetDonationRecordListParameters['type']
    status?: GetDonationRecordListParameters['status']
    campaignId?: GetDonationRecordListParameters['campaignId']
    userId?: GetDonationRecordListParameters['userId']
    receiptChoice?: GetDonationRecordListParameters['receiptChoice']
    startTime?: GetDonationRecordListParameters['startTime']
    endTime?: GetDonationRecordListParameters['endTime']
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      },
      payments: [],
      campaigns: [],
    }
  }
  tableRef?: JJ_Table<any> | null
  componentDidMount() {
    this._initData()
  }


  _getDonationRecordList = async (params: GetDonationRecordListParameters) => {
    const res = await api.GetDonationRecordList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _putDonationRecord = async (params: PutDonationRecordParameters) => {
    const res = await api.PutDonationRecord(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getPaymentList = async (params: GetPaymentListParameters) => {
    const res = await api.GetPaymentList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postSendEmailDonationConfirmation = async (params: PostSendEmailReceiptParameters) => {
    const res = await api.PostSendEmailDonationConfirmation(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postSendEmailReceipt = async (params: PostSendEmailReceiptParameters) => {
    const res = await api.PostSendEmailReceipt(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getCampaign = async (params: GetCampaignParameters) => {
    const res = await api.GetCampaign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getCampaignList = async (params: GetCampaignListParameters) => {
    const res = await api.GetCampaignList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true,
      })

      const paymentListRes = await this._getPaymentList({ limit: 1000 })
      const campaignListRes = await this._getCampaignList({ limit: 1000 })

      this.setState({
        isSpinLoading: false,
        initLoading: false,
        payments: paymentListRes.data,
        campaigns: campaignListRes.data,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    }
  }
  render() {

    return (
      <div id="DonationRecordList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯捐款记录' : '新建捐款记录'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <DonationRecordEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>

        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              window.open(`${config.REACT_APP_API}/admin/donation-record/?${new URLSearchParams({ ...filterParams(this.params), isExport: 'true' }).toString()}`)
            }}
          >
            {`導出Excel`}
          </Button>

        </Space>

        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (

            <Spin spinning={this.state.isSpinLoading}>
              <JJ_Table<DonationRecordColumnData, {
                order?: 'ASC' | 'DESC'
                paymentId?: GetDonationRecordListParameters['paymentId']
                type?: GetDonationRecordListParameters['type']
                status?: GetDonationRecordListParameters['status']
                userId?: GetDonationRecordListParameters['userId']
                campaignId?: GetDonationRecordListParameters['campaignId']
                receiptChoice?: GetDonationRecordListParameters['receiptChoice']
                rangePicker?: moment.Moment[]
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'DESC',
                        key: 'order',
                        span: 4,
                        options: [
                          {
                            value: 'ASC',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'DESC',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },
                      {
                        type: 'select',
                        defaultValue: undefined,
                        placeholder: '任意狀態',
                        allowClear: true,
                        key: 'status',
                        span: 4,
                        options: ['PENDING', 'COMPLETED'].map((item: any) => {
                          const info = getDonationRecordColumnStatusTag(item)
                          return ({
                            value: item,
                            name: info,
                            disabled: false,
                          })
                        })
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        placeholder: '任意類型',
                        allowClear: true,
                        key: 'type',
                        span: 4,
                        options: ['INDIVIDUAL', 'CORPORATE'].map((item: any) => {
                          const info = getDonationColumnTypeInfo(item)
                          return ({
                            value: item,
                            name: info.tag,
                            disabled: false
                          })
                        })
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        placeholder: '任意支付',
                        allowClear: true,
                        key: 'paymentId',
                        span: 4,
                        options: this.state.payments.map((item) => {
                          return ({
                            value: item.id,
                            name: tool.local.formatMessage({ user: this.props.user, data: item.displayName }) || '',
                            disabled: false,
                          })
                        })
                      },
                      {
                        type: 'input',
                        defaultValue: undefined,
                        key: 'userId',
                        props: {
                          placeholder: '用戶ID',
                          allowClear: true,
                        },
                      },
                      {
                        type: 'select',
                        defaultValue: undefined,
                        placeholder: '任意發票選項',
                        allowClear: true,
                        key: 'receiptChoice',
                        span: 4,
                        options: [
                          { name: '電子收據', value: 'EMAIL' },
                          { name: '實體收據', value: 'MAIL' },
                          { name: '沒有收據', value: 'NO' },
                        ].map(item => {
                          return ({
                            value: item.value,
                            name: item.name,
                            disabled: false,
                          })
                        }),
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        placeholder: '任意活動',
                        allowClear: true,
                        key: 'campaignId',
                        span: 8,
                        showSearch: true,
                        filterOption: (input: string, option: any) => {
                          if (option && option.children) {
                            const text = option.children
                            return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }
                          return false;
                        },
                        options: this.state.campaigns.map((item) => {
                          return ({
                            value: item.id,
                            name: tool.local.formatMessage({ user: this.props.user, data: item.name }) || '',
                            disabled: false,
                          })
                        })
                      },

                      {
                        type: 'rangePicker',
                        key: 'rangePicker',
                        span: 8,

                        props: {
                          placeholder: ['開始日期', '結束日期']

                        }
                      },



                    ]
                  })}
                columns={getDonationRecordColumns({
                  onAction: async (key, record) => {
                    switch (key) {
                      case 'downloadReceipt-pdf': {
                        window.open(ApiConfig.url + `/admin/download/receipt-pdf?donationRecordId=${record.id}`)
                      }
                        break
                      case 'receiptSendMail': {
                        try {
                          this.setState({
                            isSpinLoading: true,
                          })
                          await this._postSendEmailReceipt({ donationRecordId: record.id, })
                          this.tableRef && await this.tableRef.refreshData()
                          message.success(`重新觸發收據郵件成功`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        } catch (error: any) {
                          message.success(`重新觸發收據郵件失敗  （${error?.message || ''}）`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        }
                      }
                        break

                      case 'donationSendMail': {
                        try {
                          this.setState({
                            isSpinLoading: true,
                          })
                          await this._postSendEmailDonationConfirmation({ donationRecordId: record.id, })
                          this.tableRef && await this.tableRef.refreshData()
                          message.success(`重新觸發捐款郵件成功`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        } catch (error: any) {
                          message.success(`重新觸發捐款郵件失敗  （${error?.message || ''}）`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        }
                      }
                        break

                      case 'receiptUpdate': {
                        try {
                          this.setState({
                            isSpinLoading: true,
                          })
                          await this._putDonationRecord({ id: record.id, isSendReceiptEmail: !!record.receiptSentAt })
                          this.tableRef && await this.tableRef.refreshData()
                          message.success(`更新為已發郵寄收據成功`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        } catch (error: any) {
                          message.success(`更新為已發郵寄收據失敗  （${error?.message || ''}）`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        }
                      }

                      case 'updateToCompleted': {
                        try {
                          this.setState({
                            isSpinLoading: true,
                          })
                          await this._putDonationRecord({ id: record.id, status: 'COMPLETED' })
                          this.tableRef && await this.tableRef.refreshData()
                          message.success(`手動更新為已支付成功`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        } catch (error: any) {
                          message.success(`手動更新為已支付失敗  （${error?.message || ''}）`)
                          this.setState({
                            isSpinLoading: false,
                          })
                        }
                      }
                        break
                      case 'edit': {
                        this.setState(state => ({
                          editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                        }))
                      }
                        break
                    }
                  },
                  user: this.props.user,
                })}
                tableProps={{
                  scroll: {
                    x: getDonationRecordColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'捐款記錄列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body



                  this.params = {}

                  if (sourceItemBody) {
                    this.params.order = sourceItemBody.order || undefined
                    this.params.paymentId = sourceItemBody.paymentId || undefined
                    this.params.type = sourceItemBody.type || undefined
                    this.params.status = sourceItemBody.status || undefined
                    this.params.campaignId = sourceItemBody.campaignId || undefined
                    this.params.userId = sourceItemBody.userId || undefined
                    this.params.receiptChoice = sourceItemBody.receiptChoice || undefined

                    const rangePicker = sourceItemBody.rangePicker


                    if (rangePicker && rangePicker.length === 2) {
                      const [beginAtMoment, endAtMoment,] = rangePicker
                      this.params.startTime = beginAtMoment.startOf('day').toISOString()
                      this.params.endTime = endAtMoment.endOf('day').toISOString()
                    }


                  }
                  tool.route.pushParamsObjHistory(this.params, this.props.history)

                  const res = await this._getDonationRecordList({
                    ...body,
                    ...this.params,
                  })
                  return {
                    data: await Promise.all(
                      res.data.map(async item => {
                        const payment = this.state.payments.find(payment => payment.id === item.paymentId)
                        let user: GetUserRes | undefined = undefined
                        let adminUser: GetUserRes | undefined = undefined
                        let campaign = this.state.campaigns.find(campaign => campaign.id === item.campaignId)

                        return {
                          ...item,
                          payment,
                          user,
                          adminUser,
                          campaign,
                        }
                      })

                    ),
                    totalCount: res.count,
                  }
                }}
              />
            </Spin>
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DonationRecordList)

