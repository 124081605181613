import { AllErrorCode } from './api-error-code-type'

export const getApiErrorCodeText = (errorCode: AllErrorCode) => {
    switch (errorCode) {
        case 'DATA_COUNT_EXCEED_LIMIT': {
            return `查詢超過上限 (${errorCode})`
        }
        case 'INCORRECT_OLD_PASSWORD': {
            return `舊密碼不正確 (${errorCode})`
        }
        case 'INVALID_USERNAME': {
            return `用戶名稱錯誤 (${errorCode})`
        }
        case 'INCORRECT_PASSWORD': {
            return `密碼錯誤 (${errorCode})`
        }
        case 'INSUFFICIENT_PRIVILEGE': {
            return `不夠權限訪問 (${errorCode})`
        }
        case 'INVALID_PARAMS': {
            return `無效參數 (${errorCode})`
        }
        case 'INVALID_TOKEN': {
            return `請重新登錄 (${errorCode})`
        }
        case 'USER_UN_APPROVED': {
          return `此活動關聯的用戶尚未通過審批，請先前往用戶清單完成審批 (${errorCode})`
        }

        default: {
            return `系統錯誤，請聯繫開發管理人員 (${errorCode})`
        }
    }
}