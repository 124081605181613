import { Tag, Image, Avatar } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { GetAssetRes, GetUserRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getPath } from "../tool/route"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"




export const getUserColumnCategoryInfo = (type: GetUserRes['category']) => {

  switch (type) {
    case 'CORPORATE': {
      const name = '公司'
      return {
        value: type,
        name,
        tag: (
          <Tag color='pink'>
            {name}
          </Tag>
        )
      }
    }
    case 'INDIVIDUAL': {
      const name = '個人'
      return {
        value: type,
        name,
        tag: (
          <Tag color='blue'>
            {name}
          </Tag>
        )
      }
    }
    case 'SCHOOL': {
      const name = '學校'
      return {
        value: type,
        name,
        tag: (
          <Tag color='orange'>
            {name}
          </Tag>
        )
      }
    }
    case 'FA': {
      const name = '米仔'
      return {
        value: type,
        name,
        tag: (
          <Tag color='green'>
            {name}
          </Tag>
        )
      }
    }
  }
}

export type UserColumnData = GetUserRes

type UserColumnParamOnActionKey = 'edit' | 'delete' | 'showCampaign' | 'approved' | 'showDonationRecord' | 'addCampaign'
type UserColumnParams = {
  onAction?(key: UserColumnParamOnActionKey, record: UserColumnData): void
}

export const getUserColumns = (params?: UserColumnParams): ColumnsType<UserColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: UserColumnParamOnActionKey, name: string, disabled?: boolean, link?: string }[] = [
          { key: 'addCampaign', name: '添加活動', disabled: !record.allowedAddCampaign},
          { key: 'edit', name: '編輯' },
          { key: 'approved', name: '審批', disabled: !!record.isApproved },
          { key: 'showCampaign', name: '查看活動', link: getPath('/campaign/list', { userId: record.id }).key },
          { key: 'showDonationRecord', name: '查看捐款記錄', link: getPath('/payment/donation-record/list', { userId: record.id }).key },
          { key: 'delete', name: '删除' },

        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: !!item.disabled,
                key: item.key,
                name: item.name,
                link: item.link,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '電郵',
      dataIndex: 'email',
      key: 'email',
      width: 120,
    },
    {
      title: '聯繫人',
      dataIndex: 'contactPerson',
      key: 'contactPerson',
      width: 120,
    },
    {
      title: '聯繫人電話',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      width: 120,
    },
    {
      title: '類別',
      dataIndex: 'category',
      key: 'category',
      width: 80,
      render: (value, record) => getUserColumnCategoryInfo(record.category)?.tag,
    },

    {
      title: '公司/學校名',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 120,
    },

    {
      title: '語言',
      dataIndex: 'locale',
      key: 'locale',
      width: 50,
      render: (value) => {
        let name = ''
        switch (value) {
          case 'zh-tw':{
            name = '繁體中文'
            break
          }
          case 'en':{
            name = '英文'
            break
          }
        }
        return name
      }
    },
    {
      title: '驗證郵箱',
      dataIndex: 'isVerified',
      key: 'isVerified',
      width: 80,
      render: (value) => {
        return value
          ? <Avatar size="small" style={{ backgroundColor: '#5cb85c' }}>是</Avatar>
          : <Avatar size="small" style={{ backgroundColor: '#ff0000' }}>否</Avatar>
      }
    },
    {
      title: '通過審批',
      dataIndex: 'isApproved',
      key: 'isApproved',
      width: 80,
      render: (value) => {
        return value
          ? <Avatar size="small" style={{ backgroundColor: '#5cb85c' }}>是</Avatar>
          : <Avatar size="small" style={{ backgroundColor: '#ff0000' }}>否</Avatar>
      }
    },
    {
      title: '注册捐款金額',
      dataIndex: 'registerAmount',
      key: 'registerAmount',
      width: 100,
      render: (value) => {
        return `$ ${value}`
      }
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
