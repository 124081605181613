import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetAssetRes, GetApprovalRecordRes, GetUserRes, GetCampaignRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import JJ_TableItemMoment from "../components/JJ_TableItemMoment"
import tool from "../tool"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"

export type ApprovalRecordColumnData = GetApprovalRecordRes & {
  adminUser?: GetUserRes
  campaign: GetCampaignRes
  user: GetUserRes
}


export type ApprovalRecordColumnParamOnActionKey = 'delete' | 'detail'
export type ApprovalRecordColumnParams = {
  onAction?(key: ApprovalRecordColumnParamOnActionKey, record: ApprovalRecordColumnData): void
  user: ActionUserRes
}

export const getApprovalRecordColumns = (params?: ApprovalRecordColumnParams): ColumnsType<ApprovalRecordColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: ApprovalRecordColumnParamOnActionKey, name: string, disabled?: boolean, link?: string }[] = [
          { key: 'detail', name: '查看詳情', },
          { key: 'delete', name: '删除' },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: !!item.disabled,
                key: item.key,
                name: item.name,
                link: item.link,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '审批人ID',
      dataIndex: 'adminUserId',
      key: 'adminUserId',
      width: 80,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },
    {
      title: '活動ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
      width: 80,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '籌款者ID',
      dataIndex: 'userId',
      key: 'userId',
      width: 80,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '籌款者聯繫人',
      dataIndex: ['user', 'contactPerson'],
      key: 'user.contactPerson',
      width: 150,
    },
    {
      title: '籌款者聯繫人電話',
      dataIndex: ['user', 'contactNumber'],
      key: 'user.contactNumber',
      width: 150,
    },

    {
      title: '审批人',
      dataIndex: 'contactPerson',
      key: 'contactPerson',
      width: 120,
      render: (value, record) => record.adminUser?.contactPerson || record.adminUser?.email
    },

    {
      title: '活動名稱',
      dataIndex: ['campaign', 'name'],
      key: 'campaign.name',
      width: 120,
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.campaign?.name }),
    },

    {
      title: '審批時間',
      dataIndex: 'approvedAt',
      key: 'approvedAt',
      fixed: 'right',
      width: 120,
      render: (value) => (
        <JJ_TableItemMoment
          date={value}
        />
      )
    },

    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
