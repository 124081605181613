import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import api, { GetUserRes, GetUserListParameters, DeleteUserParameters, PutUserParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import UserEditAndAdd from './UserAdd';
import { connect, ConnectedProps } from 'react-redux';
import { ActionUserRes } from '../../actions/ActionUser';
import { getUserColumns, UserColumnData, getUserColumnCategoryInfo } from '../../table-columns/User';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import tool from '../../tool';
import CampaignEditAndAdd, { Type } from '../campaign/CampaignAdd';

const { confirm } = Modal;

type EditAndAddModalType = { type: 'add' } | { type: 'edit', id: string }

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType

  campaignEditAndAddModal?: {
    show: boolean
    key?: string
  } & Type

}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetUserRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class UserList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<UserColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getUserList = async (params: GetUserListParameters) => {
    const res = await api.GetUserList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteUser = async (params: DeleteUserParameters) => {
    const res = await api.DeleteUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _putUser = async (params: PutUserParameters) => {
    const res = await api.PutUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      this.setState({
        isSpinLoading: false,
      })
    } catch (error:any) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {

    return (
      <div id="UserList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯用戶' : '新建用戶'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <UserEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
              }

            }}
          />
        </Modal>

        <Modal
          maskClosable={false}
          title={this.state.campaignEditAndAddModal?.type === 'edit' ? '編輯活動' : '新建活動'}
          visible={this.state.campaignEditAndAddModal?.show}
          footer={null}
          onCancel={() => {
            if (this.state.campaignEditAndAddModal) {
              this.setState(state => ({
                campaignEditAndAddModal: { ...this.state.campaignEditAndAddModal, type: 'add', userId: '', show: false }
              }))
            }
          }}
          width={'85%'}
        >
          {this.state.campaignEditAndAddModal?.key && (
            <CampaignEditAndAdd
              {...this.state.campaignEditAndAddModal}
              onFinish={async (props) => {
                if (this.state.campaignEditAndAddModal) {
                  this.setState(state => ({
                    campaignEditAndAddModal: { ...this.state.campaignEditAndAddModal, type: 'add', userId: '', show: false }
                  }))
                }

                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>


        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<UserColumnData, {
              order?: 'ASC' | 'DESC'
              category?: UserColumnData['category']
              isApproved?: 'true' | 'false'
              isVerified?: 'true' | 'false'
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={
                tool.route.getSearchParams({
                  search: this.props.location.search,
                  sourceItems: [
                    {
                      type: 'select',
                      defaultValue: 'DESC',
                      key: 'order',
                      span: 4,
                      options: [
                        {
                          value: 'ASC',
                          name: '升序',
                          disabled: false,
                        },
                        {
                          value: 'DESC',
                          name: '降序',
                          disabled: false,
                        },
                      ]
                    },
                    {
                      type: 'select',
                      defaultValue: undefined,
                      placeholder: '任意類別',
                      allowClear: true,
                      key: 'category',
                      span: 8,
                      options: ['INDIVIDUAL', 'CORPORATE', 'SCHOOL', 'FA'].map((item: any) => {
                        const info = getUserColumnCategoryInfo(item)
                        return ({
                          value: item,
                          name: info.tag,
                          disabled: false,
                        })
                      })
                    },
                    {
                      type: 'select',
                      defaultValue: undefined,
                      placeholder: '通過審批',
                      key: 'isApproved',
                      allowClear: true,
                      options: [
                        {
                          value: 'true',
                          name: <Tag color="green">是</Tag>,
                          disabled: false,
                        },
                        {
                          value: 'false',
                          name: <Tag color="red">否</Tag>,
                          disabled: false,
                        },
                      ]
                    },
                    {
                      type: 'select',
                      defaultValue: undefined,
                      placeholder: '驗證郵箱',
                      key: 'isVerified',
                      allowClear: true,
                      options: [
                        {
                          value: 'true',
                          name: <Tag color="green">是</Tag>,
                          disabled: false,
                        },
                        {
                          value: 'false',
                          name: <Tag color="red">否</Tag>,
                          disabled: false,
                        },
                      ]
                    },
                  ]
                })

              }
              columns={getUserColumns({
                onAction: async (key, record) => {
                  switch (key) {
                    case 'addCampaign': {
                      this.setState(state => ({
                        campaignEditAndAddModal: { type: 'add', userId: record.id, show: true, key: Date().toString(), category: 'CAMPAIGN_PHOTO' }
                      }))
                    }
                      break
                    case 'delete': {
                      confirm({
                        title: `是否刪除${record.contactPerson}`,
                        icon: <ExclamationCircleOutlined />,
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk: async () => {
                          try {
                            await this._deleteUser({ id: record.id })
                            this.tableRef && this.tableRef.refreshData()
                            message.success(`${record.contactPerson} 删除成功`)
                          } catch (error:any) {
                            message.error(`${record.contactPerson} 删除失败 [${error?.message}]`);
                          }
                        },
                        onCancel() {
                        },
                      });
                    }
                      break
                    case 'edit': {
                      this.setState(state => ({
                        editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                      }))
                    }
                      break
                    case 'approved': {

                      confirm({
                        title: `是否審批${record.contactPerson}`,
                        icon: <ExclamationCircleOutlined />,
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk: async () => {
                          try {
                            await this._putUser({ id: record.id, isApproved: true })
                            this.tableRef && this.tableRef.refreshData()
                            message.success(`${record.contactPerson} 審批成功`)
                          } catch (error:any) {
                            message.error(`${record.contactPerson} 審批失败 [${error?.message}]`);
                          }
                        },
                        onCancel() {
                        },
                      });

                    }
                      break
                  }
                }
              })}
              title={'用戶列表'}
              tableProps={{
                scroll: {
                  x: getUserColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                }
              }}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  order?: GetUserListParameters['order']
                  category?: GetUserListParameters['category']
                  isApproved?: GetUserListParameters['isApproved']
                  isVerified?: GetUserListParameters['isVerified']
                } = {}
                if (sourceItemBody) {

                  params.order = sourceItemBody.order
                  params.category = sourceItemBody.category

                  switch (sourceItemBody.isApproved) {
                    case 'false': {
                      params.isApproved = false
                    }
                      break
                    case 'true': {
                      params.isApproved = true
                    }
                      break
                  }

                  switch (sourceItemBody.isVerified) {
                    case 'false': {
                      params.isVerified = false
                    }
                      break
                    case 'true': {
                      params.isVerified = true
                    }
                      break
                  }
                }

                tool.route.pushParamsObjHistory(params, this.props.history)

                const res = await api.GetUserList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return {
                  data: res.data.data,
                  totalCount: res.data.count,
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(UserList)

