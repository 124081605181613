import { Tag, Image, Avatar } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { GetAssetRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"


export type AssetColumnData = GetAssetRes

type AssetColumnParamOnActionKey = 'delete'
type AssetColumnParams = {
  onAction?(key: AssetColumnParamOnActionKey, record: AssetColumnData): void
}

export const getAssetColumns = (params?: AssetColumnParams): ColumnsType<AssetColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: AssetColumnParamOnActionKey, name: string }[] = [
          { key: 'delete', name: '删除' },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: false,
                key: item.key,
                name: item.name,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: '缩略图',
      dataIndex: 'url',
      key: 'url',
      width: 150,
      render: (value, record) => (
        <Image
          width={150}
          src={record.url}
          preview={true}
        />
      )
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (value) => {
        let name = ''
        switch (value) {
          case 'IMAGE':{
            name = '圖像'
            break
          }
          case 'VIDEO':{
            name = '視頻'
            break
          }
        }
        return name
      }
    },
    {
      title: '類別',
      dataIndex: 'category',
      key: 'category',
      width: 100,
      render: (value) => {
        let name = ''
        switch (value) {
          case 'ADVERTISEMENT':{
            name = '廣告圖片'
            break
          }
          case 'CAMPAIGN_AVATAR':{
            name = '活動頭像'
            break
          }
          case 'CAMPAIGN_PHOTO':{
            name = '活動照片'
            break
          }
        }
        return name
      }
    },
    {
      title: 'hash',
      dataIndex: 'hash',
      key: 'hash',
      width: 80,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: 'meta',
      dataIndex: 'meta',
      key: 'meta',
      width: 100,
    },


    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
